<template>
    <div class="wrapper">
        <DxTreeView id="navigation"  ref="treeview" style="padding-left: 0px;" :active-state-enabled="false" :hover-state-enabled="true" :focus-state-enabled="false" @initialized="DrawerInit" 
            class="panel-list dx-theme-accent-as-background-color" :data-source="dataSource" width="inherit" height="100%"
            @item-click="NavigationFunction($event);$emit('navigation-click', navigation);" item-template="template-TreeView" >
            <template #template-TreeView="{ data}">
               
                <div class="treeViewCustomItem">
                    <div id="flexboxNavigation">
                        <div id="flexboxNavigationImage">
                            <i :class="'dx-icon-'+data.icon+' templateItemIcon'" alt="icon" ></i>
                        </div>
                        <span class="templateItemText">{{data.text }}</span>
                        <WarningImage v-if="data.id==8 && WarningKomise" :PopisChybyKEY="$t('organizace.NekompletniKomise')" Position="fixed" ColorRGBWarningBox="rgb(0, 0, 0)" HeightIcon="20px" WidthIcon="20px"/>
                    </div>
                    
                </div>
                
            </template>
        </DxTreeView> <!-- @itemExpanded="ListImportantFunction" -->
    </div>
</template>
<script>

//import { DxList } from 'devextreme-vue/list';
import WarningImage from '@/components/WarningImage.vue';
import { navigation, navigationEN, navigationCZ } from '@/data/MenuData.js';
window.prevElement = ''
export default {
    props: ['title'],
    emits: ['navigation-click'],
   
    components: {
        WarningImage
    },
    data() {
        return {
            navigation,
            dataSource: [],// navigation,
            repeatList: true,
            WarningKomise: false
            //title: 'ahoj',
        };
    },
    created() {
        this.setDataSourceDrawer();
    },
    mounted() { 
        setTimeout(() => {
            this.ValidateInventoryCommission()
        }, 600);   
        window.thisNavigation=this
        this.$log.debug(window.jazyk)
        this.$log.debug('NAVIGATION')
        this.$log.debug(navigationEN)
        this.$log.debug(navigationCZ)
    },
    methods: {
    ValidateInventoryCommission() {
        var schovaniKomise = true
        if(schovaniKomise){
            if(window.localStorage.getItem('user')==null) {
            setTimeout(() => {
                this.ValidateInventoryCommission()
            }, 500);
        }
        else {
            this.$log.debug('Volam ValidateInventoryCommission')
            let self = this;
            var wf = new window.WorkFlow(window.channel, window.message);
            var gen = new window.General(window.channel, window.message);
            const zamid = JSON.parse(window.localStorage.getItem('user')).EmployeeID.value;
            var komise = false;
            window.gid = [];

            gen.ListLookup('GROUPLEADER:'+zamid, '', 1, 25,
                function (result) {
                    window.this.$log.debug("GroupLEADER");
                    window.this.$log.debug(result);     
                    if(result.List.value.items.length==0) self.WarningKomise = false
                    else {
                        for (var x = 0; x < result.List.value.items.length; x++) {
                            wf.GetInventoryCommission(result.List.value.items[x].Id.value, 
                                (function(itemId) {
                                    return function (result2) {
                                        window.this.$log.debug(result2);
                                        if(result2.List.value.items.length < 3) {
                                            komise = true;
                                            if (!window.gid.includes(itemId)) {
                                                window.gid.push(itemId);
                                            }  
                                            self.$log.debug(window.gid, 'window.gid');
                                        }
                                        if(x == result.List.value.items.length) {
                                            self.WarningKomise = komise;
                                        } 
                                    };
                                })(result.List.value.items[x].Id.value), 
                                window.this.ApiError()
                            );
                            }               
                    }
                }, window.this.ApiError());

            }
        }
        
    },
        setDataSourceDrawer() {
            const languagePreference = window.localStorage.getItem('jazyk');
            if (languagePreference == 'en') {
              //  this.navigation = navigationEN;
                this.dataSource = navigationEN;
            } else if (languagePreference == 'cz') {
              //  this.navigation = navigationCZ;
                this.dataSource = navigationCZ;
            } else {
                // If the language preference is not 'en' or 'cz', you can provide a default option here.
                // For example, use 'en' as the default language:
              //  this.navigation = navigationEN;
                this.dataSource = navigationCZ;
            }
            this.$log.debug('datasource')
            this.$log.debug(this.dataSource)
        },
        DrawerInit(e) {
            window.Drawer = e
            this.$log.debug(e)
            this.$log.debug(window.jazyk)
           
           // this.dataSource = this.navigationEN
            this.$log.debug(e.component.option('dataSource'))
           // e.component.option('data-source', this.navigationEN)
            
        },
        NavigationFunction(e) {
             this.$log.debug(this.$route)
            this.$log.debug(this.$router)
            this.$log.debug(e)
          // nastaveni barvy aktualnimu prokliku
          //  var oznacen = window.localStorage.getItem("Drawer-selected")
            window.localStorage.setItem("Drawer-selected", e.itemData.id)      
            switch (e.itemData.id) {
                case '1': // represents Aktuálně 
                case '1_1': // represents K mému vyjádření
                    window.localStorage.setItem("pozadavky-param", 1)
                    this.$router.push('/Pozadavky')
                    //this.$router.push({ name: 'Pozadavky', query: {Pozid: 2520372, Typ: 1} })
                    break;
                case "4": // represents Majetek
                    this.$router.push('/Majetek')
                    break;

                case "7": // represents Sklad
                    this.$router.push('/Sklad')
                    break;

                case "2": // represents Granty
                    this.$router.push('/Granty')
                    break;

                case "10": // represents Výdejky           
                    this.$router.push('/Vydejky')          // params: { title: 'test title' }
                    break;

                case "3": // represents Telefony
                 //   window.localStorage.setItem("telseznam-param", 1)
                    this.$router.push('/TelSeznam')
                    break;
                case "6": // represents Dotazovač
                    this.$router.push('/Dotazovac')
                    break;
                case "8": // represents Dotazovač
                    this.$router.push('/Organizace')
                    break;
                case "9": // represents Dotazovač
                    this.$router.push('/Aktualne')
                    break;
                default:
                // code block
            }
        },
    }
};
</script>
<style scoped>

.dx-widget {
    color:white;
    font-weight: 450;
    font-size: 1vw;
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
    line-height: 1.5;
   
}
@media screen and (max-width:700px) {
    .dx-widget {
    color:white;
    font-weight: 450;
    font-size: 1.5vw;
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
    line-height: 1.5;   
    }
    
}
@media screen and (max-width:500px) {
    .dx-widget {
    color:white;
    font-weight: 450;
    font-size: 1.8vw;
    font-family: "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
    line-height: 1.5;   
    }
    
    /*
    */
}
@media screen and (max-width:600px) {
    #flexboxNavigation{
        flex-direction: row;
    flex-wrap: wrap !important;
    justify-content: center !important;
}
.templateItemIcon{
width: 100%;
text-align: center;
}
.templateItemText{
        padding-left:0px !important;
        display: block;
        text-align:center;
        font-size: 1.75vw;
}
#flexboxNavigationImage{
    width: 100%;
    flex-grow: 1;
    align-self: center;
}
:deep(.dx-treeview-item){
    padding-left: 2px !important;
    padding-right: 6px !important;
}

    
}
:deep(.dx-scrollable-scroll-content) {
  background-color: whitesmoke !important;
}
@media screen and (max-width:999px) and (min-width:601px){
    .templateItemText{
    font-size: 1.1vw;
}

}

@media screen and (min-width:1000px) {
    
.templateItemIcon{
    font-size: 22.5px;
}
    .templateItemText{
    font-size: 1vw;
}

}

/*
@media screen and (min-width:1500px) {
    
          .templateItemText{
        font-size: 0.5vw;
    }
    
    }
      */
    
:deep(.dx-treeview-node){
    padding-left: 0px;
}
.dx-item, .dx-treeview-item{
    border-style: ridge;
    border-color: aliceblue;
    border-top: 2cm;
    border-bottom: 1cm;
    border: 5cm;
}

   

.templateItemText{
    /*font-size: 1.25vw;*/
    text-overflow: ellipsis;
        white-space: wrap;
        max-width: 100%;
        overflow: hidden;
        padding-left:4.5px;
}


#flexboxNavigation{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    text-overflow: ellipsis;
    padding: 2.5px 0px;
    /*overflow: scroll;*/
    align-content: center;
}
#flexboxNavigationImage{

    display: flex;
    align-items: center;
    /*justify-content: center;*/
    text-align: center;
    display: flex;
    

}




</style>